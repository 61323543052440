import React from "react"
import { Layout } from "../components/Layout"
import { Link } from "gatsby"

export default function WebsitePosting() {
  return (
    <Layout
      title="Job Posting"
      keywords="job posting"
      description="IrisAgent's job posting on the website"
    >

    <section class="inner-page">
          <div class="container">
            <div data-custom-class="body"
            style={{ marginBottom: "5%", marginTop: "5%", margin: "10%" }}>
            Date Posted: Oct 30, 2024<br/><br/>

Position:	 Software Engineer (multiple openings)<br/><br/>

Job Location:	1250 Borregas Ave, Sunnyvale CA 94089<br/><br/>

With a high amount of independence decision-making and minimum supervision, the Software Engineer will be responsible for the following job duties:<br/><br/>

1. Design, build, and maintain scalable, efficient, and high-quality software using programming languages such as Python, machine learning frameworks such as Pytorch and Tensorflow, cloud technologies such as Kubernetes, Docker, and CPU and CPU servers, tools such as JupyterLab. Run machine learning tests and experiments and deploy the best performing machine learning models to production servers.<br/><br/>

2. Collaborate with cross-functional teams to understand and analyze business requirements and incorporate those requirements into the software. This includes training and retraining machine learning systems and pipelines based on feedback from customers and internal teams.<br/><br/>

3. Write clean, maintainable, and well-document code using tools such as Visual Code Studio, Github, Google Docs, and Notion.<br/><br/>

4. Participate in the design and implementation of new features, including recommendation systems, machine learning classification models, generative AI language models, and natural language processing systems. Perform statistical analysis and fine-tuning using test results.<br/><br/>

5. Troubleshoot and debug software issues and provide timely solutions. This includes testing the software code using CI/CD validations and unit and integration testing, as well as resolving product issues and bugs by monitoring production alerts.<br/><br/>

6. Continuously learning new technologies and programming languages to stay up-to-date with industry trends.<br/><br/>



This position requires a U.S. Bachelor’s degree or equivalent, in CS, Analytics, or closely related, and five (5) years of experience as Software Engineer, Sr Engineer, or closely related occupation.<br/><br/>

Must have progressive professional experience with:<br/><br/>
1. GoLang;<br/>
2. Java;<br/>
3. C#;<br/>
4. System design;<br/>
5. Architecture design;<br/>
6. Product development and design; and<br/>
7. JavaScript.<br/><br/>

Salary: $226,158-$230,000 per year.<br/><br/>



Contact: Palak Dalal, Chief Executive Officer, palak@irisagent.com

    </div>
                    </div>
                    </section>
    </Layout>
  )
}
